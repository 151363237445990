export const navbar = [
    {
      title: "About",
    },
    {
      title: "Services",
    },
    {
      title: "Projects",
    },
    {
      title: "Activities",
    },
    {
      title: "Contact",
    },
  ];