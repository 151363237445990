export const activities = [
    {
      title: "100DaysOfCode",
      text: "I'm currently spending 100 days diving into interesting things!",
    },
    // {
    //   title: "JSConf Speaker",
    //   text: "Maecenas lectus massa, tincidunt et lectus non, volutpat gravida",
    // },
    // {
    //   title: "ReactConf Attendee",
    //   text: "Proin risus urna, posuere id lectus id, venenatis faucibus ligula",
    // },
  ];